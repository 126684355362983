
.family-item {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  gap: 8px;
  height: 84px;

  &.disabled {
    cursor: not-allowed;
    event-pointer: none;
    opacity: 0.5;
  }

  &:active {
    background: #f7f7f7;
    border-radius: 8px;

    .family-item-content > .family-item-title {
      color: #121212 !important;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: #f7f7f7;
      border-radius: 8px;

      .family-item-content > .family-item-title {
        color: #121212 !important;
      }
    }
  }

  .image-container {
    display: flex;
    justify-content: center;

    .image,
    .image-placeholder {
      padding-left: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 84px;
      height: 84px;

      object-fit: contain;

      mix-blend-mode: multiply;
    }
  }

  .family-item-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0 6px 8px;
    min-height: 32px;
    color: rgba(#121212, 0.75);

    > .family-item-title {

      font-size: 11px;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      font-family: "SF Pro Text"
      font-style: normal
      font-weight: 600
      line-height: 14px
    }

    > .price {
      @include label-s-regular;

      font-size: 9px;
      text-align: center;
    }

    // custom font-size for productFamily
    ::v-deep {
      .badge-wrapper {
        display: flex;
        gap: 8px;

        .badge-label {
          font-weight: 600;
          font-size: 7px;
          line-height: 14px;
          letter-spacing: 0.02em;

          @media screen and (min-width: 1024px) {
            font-weight: 600;
            font-size: 7px;
            line-height: 14px;
            letter-spacing: 0.02em;
          }
        }
      }
    }
  }
}

.badge-new {
    color: #BF4800;
}
.badge-pre-order {
    color: #336e7b;
}
